import React, { useEffect, useState } from 'react'
import { EditText } from 'react-edit-text'
import {AiFillCaretDown} from 'react-icons/ai'

function FAQitem(props) {

    const [q,setq]=useState('')
    const [a,seta]=useState('')

    useEffect(() => {
        setq(props.obj.question)
        seta(props.obj.answer)
    }, [props.obj])

    function ShowAnswer()
    {
        if(props.AnswerCheck!=props.index)
        {
            props.setAnswerCheck(props.index)
            
        }
        else
        {
            props.setAnswerCheck(-1)
        }
        console.log(props.index)
        console.log(props.AnswerCheck)
    }

    const HandleChange = (event) => {
        setq(event.value);
    }

  return (
    <div class='w-full'>
        <button class={props.AnswerCheck==props.index?'w-full p-8 bg-white/40 rounded-lg flex flex-row justify-between items-center':'w-full p-8 bg-white/40 rounded-lg flex flex-row justify-between items-center'} onClick={ShowAnswer}>
            <EditText class='text-[1.2rem] font-serif' onChange={HandleChange} type='text' value={q}> </EditText>
            <div class='flex space-x-4'>
                Click on desired question to edit it
                <AiFillCaretDown size='1.5rem'/>
            </div>
        </button>
        {
            props.AnswerCheck==props.index?
            <div class='w-full px-8 py-12 bg-black/60 text-white rounded-b-lg mb-3 text-[1rem]'>Answer: {a}}</div>
            :
            <></>
        }
    </div>
  )
}

export default FAQitem